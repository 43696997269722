import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import "../components/layout.css";
import bgBasic from "../images/brandbox-pricingBG-basic.png";
import bgBrand from "../images/brandbox-pricingBG-branded.png";
import bgCollab from "../images/brandbox-pricingBG-collab.png";
import bgAgency from "../images/brandbox-pricingBG-agency.png";

import NavBar from "../components/NavBar";
import SimpleHero from "../components/SimpleHero";
import PricingList from "../components/PricingList";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

class Pricing extends Component {

  render() {

    const {
      data: { seo, hero, pricing, contact },
    } = this.props

    const seoData = seo.primary;

    const contactData = contact.primary.cta.document.data;

    return(
      <React.Fragment>
        <SEO 
          title={ seoData.seo_title }
          description={ seoData.seo_description }
          />
        <div className="page-container pricing-page">
          <NavBar />
          <SimpleHero
            title={ hero.data.pricing_page_title.text 
            }
            teaser={ hero.data.pricing_page_teaser.text }
            />
          <div className="pricing-list-wrapper">
            {pricing.items.map((p, i) => {
              const pricingData = p.pricing_option_list.document.data;
              let bgImage;
              let txtColor;
              switch(i) {
                case 0:
                  bgImage=`url(${bgBasic})`;
                  txtColor="#fff";
                  break;
                case 1:
                  bgImage=`url(${bgBrand})`;
                  txtColor="#fff";
                  break;
                case 2:
                  bgImage=`url(${bgCollab})`;
                  txtColor="#fff";
                  break;
                case 3:
                  bgImage=`url(${bgAgency})`;
                  txtColor="#fff";
                  break;
                default:
                  bgImage=`url(${bgBasic})`;
                  txtColor="#fff";
                  break;
              }
              return (
                <div className="pricing-level-box" style={{ backgroundImage: bgImage, backgroundSize: "cover", color: txtColor }}>
                <PricingList
                  level={ pricingData.pricing_level.text }
                  image={ pricingData.pricing_image.url }
                  alt={ pricingData.pricing_image.alt }
                  currency={ pricingData.pricing_currency }
                  price={ pricingData.pricing_cost }
                  term={ pricingData.pricing_term }
                  teaser={ pricingData.pricing_teaser.text }
                  link={ pricingData.pricing_button_link.uid }
                  btnText={ pricingData.pricing_button_label.text }
                  featureList={ pricingData.pricing_feature_list.map((l, i) => {
                    return (
                        <li className="pricing-feature-item">
                          { l.feature_list_item.text }
                        </li>
                    )
                  })}
                  />
                  </div>
              )
            })}
          </div>
          <Contact
            title={ contactData.contact_field_title.text }
            teaser={ contactData.contact_field_teaser.text }
            portalId={ contactData.hubspot_portal_id.text }
            formId={ contactData.hubspot_form_id.text }
            />
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Pricing

Pricing.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.shape({
      primary: PropTypes.shape({
        seo_title: PropTypes.string.isRequired,
        seo_description: PropTypes.string.isRequired,
      }),
    }),
    hero: PropTypes.shape({
      data: PropTypes.shape({
        pricing_page_title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        pricing_page_teaser: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
      }),
    }),
    pricing: PropTypes.shape({
      items: PropTypes.array.isRequired
    }),
    pricingFeatures: PropTypes.shape({
      items: PropTypes.array.isRequired
    }),
    contact: PropTypes.shape({
      primary: PropTypes.shape({
        cta: PropTypes.shape({
          document: PropTypes.array.isRequired
        }),
      }),
    }),
  }).isRequired,
}

export const pricingQuery = graphql`
  query pricingPage {

    seo: prismicPricingPageBody1SeoTags {
      primary {
        seo_title
        seo_description
      }
    }

    hero: prismicPricingPage {
      data {
        pricing_page_title {
          text
        }
        pricing_page_teaser {
          text
        }
      }
    }

    pricing: prismicPricingPageBodyPricingList {
      items {
        pricing_option_list {
          document {
            ... on PrismicPriceList {
              data {
                pricing_level {
                  text
                }
                pricing_image {
                  url
                  alt
                }
                pricing_currency
                pricing_cost
                pricing_term
                pricing_teaser {
                  text
                }
                pricing_button_link {
                  uid
                }
                pricing_button_label {
                  text
                }
                pricing_feature_list {
                  feature_list_item {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }

    contact: prismicPricingPageBodyCta {
      primary {
        cta {
          document {
            ... on PrismicCta {
              data {
                contact_field_title {
                  text
                }
                contact_field_teaser {
                  text
                }
                hubspot_form_id {
                  text
                }
                hubspot_portal_id {
                  text
                }
              }
            }
          }
        }
      }
    }

  }
`