import React from "react";
import { linkResolver } from "../../utils/linkResolver";
import "./style.css";

export default ({ level, image, alt, currency, price, term, teaser, link, btnText, featureList }) => (
  <React.Fragment>
      <h2 className="pricing-level-title text-med">
        { level }
      </h2>
      <img src={ image } alt={ alt } className="pricing-list-image" />
      <div className="pricing-cost-wrapper">
        <h1 className="pricing-cost-amount text-med">
          <sup className="pricing-currency-symbol">{ currency }</sup>{ price }
        </h1>
        <h4 className="pricing-cost-term text-reg">
          { term }
        </h4>
      </div>
      <p className="pricing-list-teaser">
        { teaser }
      </p>
      <a className="pricing-cta-button" href={linkResolver(link)}>
        { btnText }
      </a>
      <ul className="pricing-feature-wrapper">
        { featureList }
      </ul>
  </React.Fragment>
)